import styled from "styled-components";

export const PlansSection = styled.section`
height: 100vh;
width: 100%;
display: flex;
flex-direction: column;
background-color:rgb(203, 203, 203);
padding: 50px; 
justify-content: center;
gap: 30px;

  @media (max-width: 768px) {
    height: max-content;
    padding: 50px 20px; 
  }
`;

export const PlansContent = styled.div`
display: flex;
flex-direction: row;
height: max-content;
width: 100%;
gap: 20px; 
background-color: transparent;
align-items: center;
justify-content: center;

@media (max-width: 768px) {
  flex-direction: column;
  gap: 15px; 
  }
`;

export const PlansBox = styled.a`
display: flex;
flex-direction: column;
height: max-content;
width: calc(33.333% - 20px); 
max-width: 500px; 
gap: 15px;
background-color: #fff;
padding: 30px; 
border-radius: 15px; 
text-decoration: none;
box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
transition: .5s;
&:hover{
  transform: scale(0.9);
}

@media (max-width: 768px){
width: 100%; 
}
`;

export const PlansImg = styled.img`
height: 100%;
width: auto;
`;

export const PlansTitle = styled.h3`
font-size: 24px; 
color: #000;
`;

export const PlansBoxer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
gap: 15px;
`;

export const PlansCircle = styled.div`
background-color: #101D95;
border-radius: 100%;
height: 35px;
width: 40px; 
display: flex;
justify-content: center;
align-items: center;
color: #fff;
`;

export const PlansLine = styled.hr`
width: 100%;
height: 1px;
background-color: #000;
border: none;
`;