import styled from "styled-components";

export const CarouselContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;

  h3 {
    margin-top: 20px;
    font-size: 25px;
    color: #304F42;
    text-align: center;
  }

  h4 {
    font-size: 15px;
    color: #26D07C;
    font-weight: 600;
    text-align: center;
  }

  img {
    width: 100%; 
    height: auto; 
    border-radius: 10px;
    transition: 0.3s;
    max-width: 393px; 
    padding: 10px;

    &:hover {
      transform: scale(0.9);
    }
  }

   .slick-next:before {
    content: '→';
    color: #101D95;
  }

  .slick-prev:before {
    color: #101D95;
  }


  @media (max-width: 768px) {
    img {
      max-width: 200px;
    }
  }

  @media (max-width: 1020px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const Slider = styled.div`
  .slick-slide {
    width: 100%;
    padding: 20px;

    img {
      display: block;
      margin: 0 auto;
      cursor: pointer;
    }
  }

  .slick-prev,
  .slick-next {
    font-size: 24px;
    z-index: 1;
    background: hsla(242, 88.4%, 45.3%, 1);

    &:before {
      color: #26D07C;
      background-color: #101D95;
    }
  }

  .slick-next {
    right: 30px !important;

    &:before {
      content: '→';
    }
  }

  .slick-prev {
    left: 30px !important;
  }

  .slick-dots li {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .slick-active {
    color: #000;
    background-color: #101D95;
  }
`;
