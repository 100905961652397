import {
  PlansSection,
  PlansContent,
  PlansBox,
  PlansImg,
  PlansTitle,
  PlansBoxer,
  PlansCircle,
  PlansLine,
} from "./style.js";
import Legalization from "../../assets/legalization.png";
import Tax from "../../assets/tax.png";
import Planning from "../../assets/planning.png";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

function Services() {
  return (
    <>
      <PlansSection id="services">
        <PlansContent>
          <PlansBox as="a" href="#">
            <PlansImg src={Legalization} alt="Legalização" />
            <PlansTitle>⁠Legalização de empresas</PlansTitle>
            <PlansBoxer>
              <PlansCircle>
                <ArrowOutwardIcon />
              </PlansCircle>
              <PlansLine />
            </PlansBoxer>
          </PlansBox>
          <PlansBox as="a" href="#">
            <PlansImg src={Tax} alt="Imposto de Renda" />
            <PlansTitle>Imposto de Renda</PlansTitle>
            <PlansBoxer>
              <PlansCircle>
                <ArrowOutwardIcon />
              </PlansCircle>
              <PlansLine />
            </PlansBoxer>
          </PlansBox>
          <PlansBox as="a" href="#">
            <PlansImg src={Planning} alt="Planejamento tributário" />
            <PlansTitle>Planejamento tributário</PlansTitle>
            <PlansBoxer>
              <PlansCircle>
                <ArrowOutwardIcon />
              </PlansCircle>
              <PlansLine />
            </PlansBoxer>
          </PlansBox>
        </PlansContent>
      </PlansSection>
    </>
  );
}

export default Services;
