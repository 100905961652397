import React from "react";
import { CarouselContainer, Slider as StyledSlider } from './style.js'; 
import ReactSlick from "react-slick"; 
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


export default function SimpleSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,  
    centerMode: true,
    centerPadding: "20px",
    responsive: [
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 2,  
          slidesToScroll: 1,
          centerPadding: "15px", 
        },
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1, 
          slidesToScroll: 1,
          centerPadding: "10px", 
        },
      },
      {
        breakpoint: 480, 
        settings: {
          slidesToShow: 1,  
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
    ],
  };

  return (
    <CarouselContainer>
      <ReactSlick {...settings} style={{gap: "40px"}}>
        <div className="box">
          <div className="content">
            <h2>João Batista</h2>
            <p>A equipe da Contavares é extremamente profissional! Resolveram minhas dúvidas tributárias rapidamente e com muita clareza.</p>
          </div>
        </div>
        <div className="box">
          <div className="content">
            <h2>Ana Paula Ribeiro</h2>
            <p>Contratei os serviços de planejamento financeiro da Contavares e fiquei muito satisfeita! Agora consigo organizar minhas finanças com muito mais facilidade.</p>
          </div>
        </div>
        <div className="box">
          <div className="content">
            <h2>Carlos Henrique Almeida</h2>
            <p>Precisei de auxílio na regularização da minha empresa e a Contavares foi impecável no atendimento. Recomendo sem dúvidas!</p>
          </div>
        </div>
        <div className="box">
          <div className="content">
            <h2>Mariana Lopes</h2>
            <p>A consultoria oferecida pela Contavares para redução de custos operacionais foi transformadora. Economia e eficiência, tudo em um só lugar!</p>
          </div>
        </div>
      </ReactSlick>
    </CarouselContainer>
  );
}
