import {Container,Box, Box1 } from './style.js';
import Img from "../../assets/about.png";

function About() {
  return (
   <>
    <Container id='who'>
        <Box1>
            <h1>Sobre nós</h1>
            <p>Na Contavares Assessoria Contábil, entendemos que uma contabilidade eficiente
              é essencial para o sucesso de qualquer empresa. Por isso, oferecemos uma ampla 
              gama de serviços contábeis personalizados para atender às necessidades específicas de cada negócio.</p>
            <a href='https://w.app/contavares' target='_blank'>Fale conosco</a>
        </Box1>
        <Box>
            <img src={Img} />
        </Box>
    </Container>
   </>
  );
}

export default About;
